// import Swal from 'sweetalert2' - вирус хохлов
// require('./bootstrap');

// jQuery(function($){
//     // $.mask.autoclear = !1;
//     $("INPUT[type=tel]").mask("+7 (999) 999-99-99",{"placeholder":" "});
//     //$("#tin").mask("99-9999999");
//     //$("#ssn").mask("999-99-9999");
//     //$("#date").mask("99/99/9999",{placeholder:"mm/dd/yyyy"});
//  });

jQuery(document).ready(function(){
    //jQuery('[data-toggle="tooltip"]').tooltip();
    const exp1 = new PhoneReplacer(jQuery('INPUT[type=tel]'), {
        masks: [
            ['+7 (DDD) DDD-DD-DD', ['+7', '7']],
            ['8 (DDD) DDD-DD-DD', ['8']],
            // ['+38 (DDD) DDD-DD-DD', ['+38', '38', '80']],
        ],
        masksDef: '+7 (DDD) DDD-DD-DD',
        placeholder: '_',
        addPlaceholder: true
    });
    
    // scroll top page
    jQuery('#btnUpPageAll').on('click', function(){
        var thisObj = jQuery(this);
        //thisObj.removeClass('active');
        jQuery('html, body').animate({ scrollTop: jQuery('body').offset().top }, 1000);
    });
    // scroll get
    jQuery(window).on('scroll', function(){
        var thisObj = jQuery(this),
            topList = parseFloat(window.pageYOffset);
        if(topList < 1000){
            jQuery('#btnUpPageAll').hasClass('active') ? jQuery('#btnUpPageAll').removeClass('active') : false;
        } else {
            !jQuery('#btnUpPageAll').hasClass('active') ? jQuery('#btnUpPageAll').addClass('active') : false;
        }
    });
    jQuery('.btnGetLink').on('click', function(){
        var thisObj = jQuery(this),
            target = thisObj.data('target'),
            blockTarget = typeof target != "undefined" ? jQuery(target) : false;
        // console.log(target);
        //thisObj.removeClass('active');
        if(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0){
            jQuery('html, body').animate({ scrollTop: blockTarget.offset().top - 50 }, 1000);
            return false;
        }
    });
    jQuery('BODY').on('click', '.imgToBigClick:not(.active)', function(){
        var thisObj = jQuery(this),
            target = thisObj.data('target'),
            targetUnset = thisObj.data('target-unset'),
            blockTarget = typeof target != "undefined" ? jQuery(target) : false;
        // console.log(target);
        //thisObj.removeClass('active');
        if(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0){
            jQuery(targetUnset + '.active').removeClass('active');
            jQuery(target).addClass('active');
            jQuery('.imgToBigClick.active').removeClass('active');
            thisObj.addClass('active');
            return false;
        }
    });
    
    
    jQuery('INPUT.numberInput').on('keypress', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];
        
        //console.log(String.fromCharCode(e.which));
        //console.log(list.indexOf(String.fromCharCode(e.which)));
        if(String.fromCharCode(e.which) == '.'){
            if(val.length <= 0){
                val = '0.';
                jQuery(this).val('0.');
            }
            if(val.indexOf('.') >= 0){
                return false;
            }
        }
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });
    jQuery('INPUT.numberInputInt').on('keypress', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });
    jQuery('INPUT.numberInput, INPUT.numberInputInt').on('change', function(e){
        var numberText = jQuery(this).val();
        numberText = numberText.replace(/ /, '');
        numberText = parseFloat(numberText);
        if(isNaN(numberText)){
            numberText = 0;
        }
        jQuery(this).val(numberText);
        return false;
    });
    
    // INPUT LABEL
    jQuery('.inputBlock.labelPlaceholder INPUT').on('focus', function(e){
        var thisObj = jQuery(this),
            inputBlock = thisObj.closest('.inputBlock');
        inputBlock.addClass('active');
        //return false;
    }).on('blur', function(e){
        var thisObj = jQuery(this),
            inputBlock = thisObj.closest('.inputBlock');
        if(thisObj.val().length > 0){
            inputBlock.addClass('hasData');
        } else if(thisObj.val().length == 0 && inputBlock.hasClass('hasData')){
            inputBlock.removeClass('hasData');
        }
        inputBlock.removeClass('active');
        //return false;
    });
    
    // INPUT change ERROR
    jQuery('BODY').on('click', 'INPUT.error, TEXTAREA.error, SELECT.error, CHECKBOX.error', function(){
        var thisObj = jQuery(this);
        thisObj.removeClass('error');
    });
    
    
    
    /* RESIZE PAGE */
    jQuery(window).resize(function(){
        //var size = $(window).width();
        typeof jQuery('.btnOpenMenu.active') != "undefined" && jQuery('.btnOpenMenu.active').length > 0 ? getMobileMenu(jQuery('.btnOpenMenu.active')) : false;
        //console.log(size);
    });
    /* END RESIZE PAGE */
    
    
    /* windowHead */
    var scrollEndPoint = jQuery(window).scrollTop();
    function getHeadClassTop(){
        let head = jQuery('.ownerHead'),
            scrollT = jQuery(window).scrollTop(),
            hasTop = head.hasClass('topFixed');
        if(scrollT > 10 && !hasTop){
            head.addClass('topFixed');
        } else if(scrollT < 10 && hasTop){
            head.removeClass('topFixed');
            hasTop = false;
        }
        if(scrollT > 100 && scrollEndPoint < scrollT && hasTop){
            head.addClass('topClear');
        } else if(scrollT > 100 && scrollEndPoint > scrollT && hasTop){
            head.removeClass('topClear');
        }
        //console.log(scrollEndPoint);
        //console.log(scrollT);
        scrollEndPoint = scrollT;
    }
    getHeadClassTop();
    jQuery(window).on('scroll', function(e){
        getHeadClassTop();
    });
    jQuery(window).on('mousemove', function(e){
        let currentMousePosY = event.pageY - jQuery(window).scrollTop();
        //console.log(currentMousePosY);
        if(currentMousePosY < 90){
            scrollEndPoint = scrollEndPoint * 2;
            getHeadClassTop();
        }
    });
    /* end windowHead */
    
    /* windowMenu */
    function getMobileMenu(obj){
        var thisObj = obj,
            btnActive = thisObj.hasClass('active'),
            target = thisObj.data('target'),
            targetBtns = thisObj.data('btns'),
            listBtns = jQuery('.'+targetBtns),
            objTarget = jQuery('#'+target);
        if(!btnActive){
            //jQuery('BODY').addClass('blow');
            jQuery('BODY').addClass('modal-open');
            thisObj.addClass('active');
            listBtns.addClass('active');
            objTarget.addClass('active');
            autoHeightAnimate(objTarget, 200);
            objTarget.animate({"opacity": "1"}, 10).addClass('active');
        } else {
            //jQuery('BODY').removeClass('blow');
            jQuery('BODY').removeClass('modal-open');
            thisObj.removeClass('active');
            listBtns.removeClass('active');
            autoHeightAnimate(objTarget, 200);
            // objTarget.animate({"height": "0"}, 200);
            objTarget.animate({"opacity": "0"}, 200, function(){jQuery(this).removeClass('active')});
        }
    }
    jQuery('.btnOpenMenu').on('click', function(e){
        var thisObj = jQuery(this);
        getMobileMenu(thisObj)
        return false;
    });
    jQuery('BODY').on('click', function(e){
        let activeMenu = jQuery(this).find('.btnOpenMenu.active');
        if(activeMenu.length && jQuery(e.target).closest('.wraperHeadMobile').length == 0){
            getMobileMenu(activeMenu);
        }
        // console.log(e.target);
        // if(e.target === this){
        //     getMobileMenu(thisObj)
        // }
    });
    jQuery('.wraperHeadMobile a.getModal').on('click', function(e){
        var thisObj = jQuery(this),
            thisObjBtn = jQuery(this).parents('.wraperHeadMobile').find('.btnOpenMenu');
        getMobileMenu(thisObjBtn)
        //return false;
    });
    jQuery('.getBody.nav-link').on('click', function(e){
        var thisObj = jQuery(this),
            link = thisObj.attr('href'),
            //target = link.replace(/^\/\?\#/, ''),
            target = thisObj.data('target'),
            blockTarget = typeof target != "undefined" ? jQuery('#'+target) : false;
        jQuery('.btnOpenMenu').click();
        //console.log(target);
        //console.log(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0);
        if(typeof blockTarget != "undefined" && blockTarget != false && blockTarget.length > 0){
            jQuery('html, body').animate({ scrollTop: (blockTarget.offset().top - 100) }, 1000);
            history.pushState(null, null, link);
            //return false;
        } else {
            window.location.href = link;
        }
        // easeOutCubic
        return false;
    });
    // child menu
    jQuery('.dropdown SPAN.nav-link.dropdown-toggle').on('click', function(e){
        var thisObj = jQuery(this),
            dropdown = thisObj.closest('.dropdown'),
            active = dropdown.hasClass('open');
        if(!active){
            jQuery('.nav .dropdown').removeClass('open');
            dropdown.addClass('open');
        } else {
            dropdown.removeClass('open');
        }
        return false;
    });
    jQuery('BODY').on('click', function(e){
        let activeMenu = jQuery(this).find('.dropdown.open');
        if(activeMenu.length && jQuery(e.target).closest('.dropdown.open').length == 0){
            activeMenu.removeClass('open');
        }
    });
    /* end windowMenu */
    
    
    
    /* Function to animate height: auto */
    function autoHeightAnimate(element, time){
        var curHeight = element.height(), // Get Default Height
            autoHeight = element.css('height', 'auto').height(); // Get Auto Height
        element.height(curHeight); // Reset to Default Height
        element.stop().animate({ height: autoHeight }, time); // Animate to Auto Height
    }
    
    
    // run video
    jQuery('.runVideo').on('click', function(){
        var thisObj = jQuery(this),
            videoRun = thisObj.data('video'),
            frameData = thisObj.data('frame'),
            video = jQuery('#'+videoRun);
        jQuery('.runVideo .btnPlay').hide();
        jQuery('.runVideo .usVideo').show();
        jQuery(frameData)
            .load(function() {
                jQuery(this).prop('contentWindow').postMessage('{"event": "command", "func": "playVideo", "args": ""}', "*");
            })
            //.attr("frameborder", 0)
            //.attr("allowfullscreen", 1)
            .appendTo(video);
        //video.append(frameData);
        //thisObj.hide();
        //setTimeout(function(){video.find('FRAME')[0].contentWindow.postMessage('{"event": "command", "func": "playVideo", "args": ""}', "*")}, 2500);
    });
    

    // open modal
    var oldLabelModal = '', oldDataLabelModal = '';
    jQuery('.getModal').on('click', function(){
        var thisObj = jQuery(this),
            target = thisObj.data('bs-target'),
            modalBlock = jQuery(target),
            modalLabel = typeof modalBlock != "undefined" ? modalBlock.find('.modal-header .h3') : '',
            label = thisObj.data('label');
        // console.log(label);
        // console.log(modalBlock.find('FORM').data('label'));
        if(typeof label != "undefined" && label != ''){
            if(typeof modalLabel != "undefined" && modalLabel.text() != '' && modalLabel.length > 0){
                oldLabelModal = modalLabel.text();
                modalLabel.text(label);
            }
            oldDataLabelModal = modalBlock.find('FORM').data('label');
            modalBlock.find('FORM').data('label', label);
        }
    });
    jQuery('.modalWindow').on('hide.bs.modal', function(){
        var thisObj = jQuery(this),
            modalLabel = thisObj.find('.modal-header .h3');
        if(typeof modalLabel != "undefined" && oldLabelModal != ''){
            modalLabel.text(oldLabelModal);
            thisObj.find('FORM').data('label', oldDataLabelModal);
        }
        // getAlert('success', 'Ну хорошо', 'Закрыли: '+modalLabel.text(), 'top-end', false);
    });
    
    
    
    window.getAlert = function (type, title, text, position = '', showConfirm = true, template = ''){
        // Swal.fire({
        //     position: position ? position : 'default',
        //     title: title,
        //     text: text,
        //     type: type,
        //     showConfirmButton: showConfirm,
        //     confirmButtonClass: 'btn btn-success',
        //     confirmButtonText: 'Хорошо',
        //     template: template ? template : 'default', //'#my-template'
        //     icon: 'warning',
        //     // timer: 3500,
        //     // showClass: {
        //     //     popup: 'animate__animated animate__fadeInDown'
        //     // },
        //     // hideClass: {
        //     //     popup: 'animate__animated animate__fadeOutUp'
        //     // }
        // });
        jQuery('#alertModal .modal-body .h2').text(title);
        jQuery('#alertModal .modal-body .hDesc').text(text);
        //jQuery('#alertModal').modal();
        let modal = new window.bootstrap.Modal(jQuery('#alertModal'));
        modal.show();

    }

    
    // MODAL SEND
    jQuery('FORM[name=callMeGo]').submit(function(){
        var thisObj = jQuery(this),
            name = thisObj.find('INPUT[name=name]'),
            phone = thisObj.find('INPUT[name=phone]'),
            email = thisObj.find('INPUT[name=email]'),
            square = thisObj.find('INPUT[name=square]'),
            stock = thisObj.find('SELECT[name=stock]'),
            message = thisObj.find('TEXTAREA[name=message]'),
            url = thisObj.data('url'),
            iagree = thisObj.find('INPUT[name=politics]'),
            label = thisObj.data('label'),
            price = thisObj.data('price'),
            allOk = true,
            post = [];
        if(typeof name != "undefined"){
            if(name.val() == ''){
                getAlert('error', 'Не все поля заполненны!', 'Введите свое имя!');
                name.addClass('error');
                name.focus();
                return false;
            } else {
                name = name.val();
            }
        } else {
            name = '';
        }
        if(typeof phone == "undefined" || typeof phone.val() == "undefined" || phone.val() == ''){
            phone = '';
        } else {
            if(phone.val() == ''){
                getAlert('error', 'Не все поля заполненны!', 'Введите свой телефон!');
                phone.focus();
                return false;
            } else {
                var r2 = /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i,
                    r8 = /^8 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i;
                    // r8 = /^\+7 \(8[0-9]{2}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/i;
                console.log(phone.val());
                if(!r2.test(phone.val()) && !r8.test(phone.val())){
                    getAlert('error', 'Не все поля заполненны!', 'Не верно введен телефон!');
                    phone.addClass('error');
                    phone.focus();
                    return false;
                }
            }
            phone = phone.val();
        }
        if(typeof email == "undefined" || typeof email.val() == "undefined" || email.val() == ''){
            email = '';
        } else {
            email = email.val();
        }
        if(typeof square == "undefined" || typeof square.val() == "undefined" || square.val() == ''){
            square = '';
        } else {
            square = square.val();
        }
        if(typeof stock != "undefined" && typeof stock.val() != "undefined"){
            if(stock.val() == '' || stock.val() == null){
                getAlert('error', 'Не все поля заполненны!', 'Не выбран склад!');
                stock.addClass('error');
                stock.focus();
                return false;
            } else {
                stock = stock.val();
            }
        } else {
            stock = '';
        }
        if(typeof message == "undefined" || typeof message.val() == "undefined" || message.val() == ''){
            message = '';
        } else {
            message = message.val();
        }
        if(typeof url == "undefined"){
            url = '';
        }
        //console.log(typeof iagree);
        //console.log(iagree.val());
        //console.log(iagree);
        if(typeof iagree == "undefined" || typeof iagree.val() == "undefined" || iagree.val() == ''){
            iagree = '';
        } else {
            if(iagree.prop('checked') != true){
                getAlert('error', 'Не все поля заполненны!', 'Согласие с пользовательским соглашением и политикой конфиденциальности обязательно!');
                allOk = false;
                return false;
            }
        }
        
        if(allOk){
            post = {name:name, phone:phone, email:email, label:label, url:url, other:message, price:price, square:square, stock:stock};
            // console.log(JSON.stringify(post));
            callMeGo(JSON.stringify(post), thisObj);
        }
        return false;
    });
    
    

    function callMeGo(post, thisObj){
        jQuery.ajax({
            url    : "/ajax/mail-to",
            type   : 'POST',
            //dataType : 'json',
            data : {data: post},
            headers: { 'X-CSRF-Token': thisObj.find('INPUT[name=_token]').val() },
            cache  : false,
            //success: function(data){}
        })
        .done( function(data){
                // console.log(data);
                //data = JSON.parse(data);
                if( data.status ){
                    // Swal.fire({
                    //     title: 'Спасибо за заявку!',
                    //     text: 'Мы свяжемся с Вами в ближайшее время!',
                    //     type: 'success',
                    //     showConfirmButton: false,
                    //     confirmButtonClass: 'btn btn-success',
                    //     confirmButtonText: 'Хорошо',
                    //     icon: 'success',
                    // });
                    getAlert('success', 'Спасибо за заявку!', 'Мы свяжемся с Вами в ближайшее время!', '', false);
            
                    // jQuery('#alertModal .modal-header .h3').text('Спасибо за заявку!');
                    // jQuery('#alertModal .modal-header .hDesc').text("Мы свяжемся с Вами в ближайшее время!");
                    // //jQuery('#alertModal').modal();
                    // let modal = new bootstrap.Modal(jQuery('#alertModal'));
                    // modal.show();
                    jQuery('.modalWindow').modal('hide');
                    jQuery('INPUT[name=name]').val('');
                    jQuery('INPUT[name=phone]').val('');
                    jQuery('INPUT[name=email]').val('');
                    jQuery('TEXTAREA').val('');
                    // jQuery('SELECT').val('0');
                    ym(92724907,'reachGoal','sendFeadbuck');
                } else {
                    getAlert('error', 'Ошибка на сервере', 'Повторите чуть позже ('+data.message+')');
                    // jQuery('#alertModal .modal-header .h3').text('Ощибка на сервере');
                    // jQuery('#alertModal .modal-header .hDesc').text("Повторите чуть позже ("+data.message+")");
                    // //jQuery('#alertModal').modal();
                    // let modal = new bootstrap.Modal(jQuery('#alertModal'));
                    // modal.show();
                }
            }
        )
        .fail(function(data){
            console.log(data);
            //alert('Error');
            }
        );
    }

});







